@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

* {
    font-size: 16px;
    font-family: 'Exo', sans-serif;
}


h1 {
    font-size: 3.0rem;
    font-weight: 600;
}
h2 {
    font-size: 2rem;
    font-weight: 500;
}
h3 {
    font-size: 1.5rem;
    font-weight: 500;
}
h4 {
    font-size: 1.1rem;
    font-weight: 500;
}

p, a, i, li, tr, th, td, dl, dt, dd, ol, ul, u, tt {
    font-weight: 400;
}