.button-primary {
    color: $dark-text;
    background-color: $primary;

    border: 0px;
    border-radius: 8px;

    padding: 8px 20px 8px 20px;

    cursor: pointer;
}

.button-primary:disabled {
    background-color: rgb(85, 85, 85) !important;
}

.button-secondary {
    color: $light-text;
    background-color: transparent;

    border: 1px solid black;
    border-radius: 8px;

    padding: 8px 20px 8px 20px;
    
    cursor: pointer;

}

.button-secondary:disabled {
    color: rgb(85, 85, 85) !important;
    border: 1px solid rgb(85, 85, 85) !important;
}

.button-secondary-light {
    color: $dark-text;
    background-color: transparent;

    border: 1px solid $dark-text;
    border-radius: 8px;

    padding: 8px 20px 8px 20px;
    
    cursor: pointer;
}

.dark-theme {
    .button-secondary {
        color: $dark-text;
        background-color: transparent;

        border: 1px solid white;
    }
}