@import 'abstracts/colors';
@import 'base/theme';

navbar {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0px;
    z-index: 3;
}

.center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

html, body, app {
    height: 100%;
}

.section-header {
    height: 10%;

    padding: 4rem 2rem 25px 2rem;

    h2 {
        margin-bottom: 15px;
    }

    hr {
        border-top: 2px;
        border-top-style: solid;
        @include themify($themes) {
            border-top-color: themed('textColor');
        }

        height: 0;
        margin: 0;
    }
}

.card {
    // width: 400px;

    aspect-ratio: 1 / 1;

    border: none;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    h4 {
        margin: 10px 0 5px 0;
    }
}

.card-round {
    aspect-ratio: 1 / 1;

    border: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
        height: 100%;
        width: 100%;

        aspect-ratio: 1 / 1;

        border-radius: 50%;

        object-fit: cover;

        // flex: 1;
    }

    h4 {
        margin: 10px 0 5px 0;
    }

    .card-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    
        margin: 10px;
    }
}

.card-set {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 1rem;

    padding: 0 10px 0 10px;
} 