@import 'abstracts/colors';

$themes: (
  light: (
    backgroundColor: $light-bg,
    accentBackgroundColor: $light-bg-accent,
    textColor: $light-text,
    overlayBackground: $light-footer-fill
  ),
  dark: (
    backgroundColor: $dark-bg,
    accentBackgroundColor: $dark-bg-accent,
    textColor: $dark-text,
    overlayBackground: $dark-footer-fill
  ),
);

@mixin themify($themes: $themes) {
    @each $theme, $map in $themes {

      ::ng-deep .#{$theme}-theme & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }

        @content;
        $theme-map: null !global;
      }

      .#{$theme}-theme & {
        $theme-map: () !global;
        @each $key, $submap in $map {
          $value: map-get(map-get($themes, $theme), '#{$key}');
          $theme-map: map-merge($theme-map, ($key: $value)) !global;
        }

        @content;
        $theme-map: null !global;
      }

    }
}


@function themed($key) {
    @return map-get($theme-map, $key);
}

.dark-theme {

    section, article, hr, div:not(.cdk-overlay-dark-backdrop):not(.cdk-global-overlay-wrapper):not(.cdk-overlay-backdrop):not(.cdk-overlay-pane), body, app-footer, input, textarea {
      background-color: $dark-bg;
    }

    router-outlet + * {
      background-color: $dark-bg;
    }

    h1, h2, h3, h4, h5, li, a, span, p, label, input, textarea, tr, th {
      color: $dark-text;
    }

    input, textarea {
      border: 1px solid $dark-text;
      border-radius: 10px;

      padding: 10px;
    }
}

.light-theme {
    section, article, hr, div:not(.cdk-overlay-dark-backdrop):not(.cdk-global-overlay-wrapper):not(.cdk-overlay-backdrop):not(.cdk-overlay-pane), body, app-footer, input, textarea {
        background-color: $light-bg;
    }

    router-outlet + * {
      background-color: $light-bg;
    }

    h1, h2, h3, h4, h5, li, a, span, p, label, input, textarea, tr, th {
      color: $light-text;
    }

    input, textarea {
      border: 1px solid $light-text;
      border-radius: 10px;
      padding: 10px;
    }
}

.error {
  color: red;
}
