// //
// // App-wide Styles
// //
// @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

// @import 'abstracts/colors';

html, body {
    height: 100%;
    width: 100%;
}

app {
    #main-wrapper {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}



router-outlet + * {
    flex: 1;
}

// .browserupgrade {
//     margin: 0.2em 0;
//     background: #ccc;
//     color: #000;
//     padding: 0.2em 0;
// }

// * {
//     box-sizing: border-box;
//     box-shadow: none;
// }

// [hidden] {
//     display: none !important;
// }

// .webp .dark-theme {
//     .bg-img {
//         background: url("assets/images/misc/parts_dark.webp") center / cover no-repeat;
//     }
// }

// .no-webp .dark-theme {
//     .bg-img {
//         background: url("assets/images/misc/parts_dark.png") center / cover no-repeat;
//     }
// }

// .dark-theme {

//     body,
//     .bg,
//     .row,
//     .container-fluid {
//         background: $dark-bg;
//     }

//     h1,
//     h2,
//     h3,
//     h4,
//     h5,
//     a,
//     p,
//     label,
//     input,
//     span,
//     li,
//     mat-form-field,
//     mat-error,
//     input,
//     i {
//         color: white;
//     }

//     .bg-color,
//     .mat-snack-bar-container {
//         background-color: #121212;
//     }

//     .bg-elevate {
//         background: #12121255;
//     }

//     .header {
//         background-color: #121212aa;
//     }

//     .cookie-bar {
//         background: #132d4b;
//         color: #ffffffbb;
//     }
// }

// .webp .light-theme {
//     .bg-img {
//         background: url("assets/images/misc/partsbig.webp") center / cover no-repeat;
//     }
// }

// .no-webp .light-theme {
//     .bg-img {
//         background: url("assets/images/misc/partsbig.png") center / cover no-repeat;
//     }
// }

// .light-theme {

//     body,
//     .bg,
//     .row,
//     .container-fluid {
//         background: $light-bg;
//     }
    
//     .bg-color,
//     .mat-snack-bar-container {
//         background: #ffffff;
//     }

//     .bg-elevate {
//         background-color: #ffffff30;
//     }

//     .header {
//         background: #ffffffbb;
//     }

//     .cookie-bar {
//         background: #ffffffbb;
//         color: #132d4b;
//     }
// }

// body {
//     overflow-x: hidden;
// }

// .pointer {
//     cursor: pointer;
// }

// router-outlet+* {
//     width: 100%;
//     height: 100%;
//     overflow-x: hidden;
//     overflow-y: hidden;
//     display: inline-block;
// }

// router-outlet {
//     display: none;
// }

// .grow {
//     flex-grow: 1;
//     margin-top: 10vh;
// }

// // h1 {
// //     font-size: clamp(0.5rem, -0.875rem + 8.333vw, 2.5rem);
// //     font-weight: 500;
// // }

// // h3 {
// //     font-size: clamp(0.3rem, -0.875rem + 8.333vw, 1.5rem);
// // }

// h1,
// h2,
// h3,
// h4,
// h5,
// a,
// p,
// button,
// label,
// input,
// span,
// li,
// mat-form-field,
// mat-error,
// input {
//     font-family: "Exo", monospace !important;

//     margin: 0;
// }

// // span {
// //     font-size: clamp(0.3rem, -0.875rem + 8.333vw, 1.3rem);
// // }

// a {
//     text-decoration: none;
// }

// span.line {
//     display: inline-block;
//     // font-size: clamp(0.5rem, -0.875rem + 8.333vw, 2rem);
// }

// .logo-lg {
//     width: 100%;
//     height: auto;
// }

// .nav {
//     top: 0;
//     width: 100%;
//     z-index: 1000;
// }

// footer {
//     bottom: 0;
//     width: 100%;
//     z-index: 1000;
// }

// .scrollable {
//     overflow-y: scroll;
// }

// .page-wrapper {
//     overflow-y: hidden;
// }

// .page-header {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-evenly;
// }

// .bg-elevate {
//     box-shadow: 10px 10px #121212;
// }

// .row {
//     min-height: 25vh;
//     padding: 40px 0 40px 0;
// }

// .row-img {
//     width: 30%;
//     height: auto;
//     object-fit: contain;
// }

// .no-pad {
//     padding: 0;
// }

// .row-short {
//     min-height: 10vh !important;

//     margin: 0 0 20px 0;
// }

// .container-fluid {
//     padding: 0;
// }

// .header {
//     height: 100%;
//     width: 100%;

//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
// }

// button {
//     margin: 10px !important;
// }

// // Cookie Popup Style
// .cookie-bar {
//     button {
//         color: #ffffffbb;
//     }
// }

// // From https://github.com/angular/components/issues/4580#issuecomment-510290576
// // Fixes issues with error messages with material inputs overlapping the next input
// mat-form-field {
//     &.ng-valid {
//         .mat-form-field-wrapper {
//             padding-bottom: 1.25em;
//         }
//     }

//     &.ng-invalid,
//     &.mat-form-field-invalid {
//         .mat-form-field-wrapper {
//             padding-bottom: 7px;
//         }
//     }

//     &.ng-untouched {
//         .mat-form-field-wrapper {
//             padding-bottom: 1.25em;
//         }
//     }

//     .mat-form-field {
//         &-underline {
//             position: static;
//         }

//         &-subscript-wrapper {
//             position: static;
//         }
//     }
// }

// .mat-mdc-snack-bar-container,
// .mdc-snackbar__surface {

//     --mdc-snackbar-container-color: #00000000 !important;
//     box-shadow: none !important;

// }



// /// Cookie popup stuff. I had to move it here as it seems like 
// ///     it wan't getting applied when in the file in the component
// /// 
// .cookie-popup {
//     background-color: #121212; // Adjust transparency as needed
//     color: white;
//     padding: 16px;
//     border-radius: 5px;
// }

// .dark-theme .cookie-popup {
//     background-color: #121212; // Dark theme background color
//     color: white; // Dark theme text color
//     border: 1px solid white;
// }

// /// Cookie popup stuff. I had to move it here as it seems like 
// ///     it wan't getting applied when in the file in the component
// /// 
// .cookie-popup {
//     background-color: #121212; // Adjust transparency as needed
//     color: white;
//     padding: 16px;
//     border-radius: 5px;
// }

// .dark-theme .cookie-popup {
//     background-color: #121212; // Dark theme background color
//     color: white; // Dark theme text color
//     border: 1px solid white;
// }

// .light-theme .cookie-popup {
//     background-color: rgba(255, 255, 255, 1); // Light theme background color
//     color: black; // Light theme text color
//     border: 1px solid black;
// }

// .cookie-popup a {
//     // color: inherit;
//     text-decoration: none;

//     &:hover {
//         text-decoration: underline;
//     }
// }

// .button-container {
//     display: flex;
//     justify-content: space-between;
// }

// .accept-button,
// .decline-button {
//     background-color: transparent;
//     color: inherit;
//     border: 1px solid currentColor;
//     padding: 4px 8px;
//     border-radius: 4px;
//     text-transform: uppercase;
//     font-weight: bold;
//     cursor: pointer;
//     transition: background-color 0.3s, color 0.3s; // Add a transition for a smooth color change

//     &:hover {
//         background-color: #00000033;
//     }
// }

// .dark-theme .accept-button:hover,
// .dark-theme .decline-button:hover {
//     color: rgba(255, 255, 255, 0.8);
// }