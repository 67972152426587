/*
 * General Stylistic Colors/Gradients
 */
@import url("https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  font-size: 16px;
  font-family: "Exo", sans-serif;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
}

h4 {
  font-size: 1.1rem;
  font-weight: 500;
}

p, a, i, li, tr, th, td, dl, dt, dd, ol, ul, u, tt {
  font-weight: 400;
}

/*
 * General Stylistic Colors/Gradients
 */
.dark-theme section, .dark-theme article, .dark-theme hr, .dark-theme div:not(.cdk-overlay-dark-backdrop):not(.cdk-global-overlay-wrapper):not(.cdk-overlay-backdrop):not(.cdk-overlay-pane), .dark-theme body, .dark-theme app-footer, .dark-theme input, .dark-theme textarea {
  background-color: #0B0B22;
}
.dark-theme router-outlet + * {
  background-color: #0B0B22;
}
.dark-theme h1, .dark-theme h2, .dark-theme h3, .dark-theme h4, .dark-theme h5, .dark-theme li, .dark-theme a, .dark-theme span, .dark-theme p, .dark-theme label, .dark-theme input, .dark-theme textarea, .dark-theme tr, .dark-theme th {
  color: #F9F6FF;
}
.dark-theme input, .dark-theme textarea {
  border: 1px solid #F9F6FF;
  border-radius: 10px;
  padding: 10px;
}

.light-theme section, .light-theme article, .light-theme hr, .light-theme div:not(.cdk-overlay-dark-backdrop):not(.cdk-global-overlay-wrapper):not(.cdk-overlay-backdrop):not(.cdk-overlay-pane), .light-theme body, .light-theme app-footer, .light-theme input, .light-theme textarea {
  background-color: #F5F5F6;
}
.light-theme router-outlet + * {
  background-color: #F5F5F6;
}
.light-theme h1, .light-theme h2, .light-theme h3, .light-theme h4, .light-theme h5, .light-theme li, .light-theme a, .light-theme span, .light-theme p, .light-theme label, .light-theme input, .light-theme textarea, .light-theme tr, .light-theme th {
  color: #11111E;
}
.light-theme input, .light-theme textarea {
  border: 1px solid #11111E;
  border-radius: 10px;
  padding: 10px;
}

.error {
  color: red;
}

.button-primary {
  color: #F9F6FF;
  background-color: #4F55E0;
  border: 0px;
  border-radius: 8px;
  padding: 8px 20px 8px 20px;
  cursor: pointer;
}

.button-primary:disabled {
  background-color: rgb(85, 85, 85) !important;
}

.button-secondary {
  color: #11111E;
  background-color: transparent;
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px 20px 8px 20px;
  cursor: pointer;
}

.button-secondary:disabled {
  color: rgb(85, 85, 85) !important;
  border: 1px solid rgb(85, 85, 85) !important;
}

.button-secondary-light {
  color: #F9F6FF;
  background-color: transparent;
  border: 1px solid #F9F6FF;
  border-radius: 8px;
  padding: 8px 20px 8px 20px;
  cursor: pointer;
}

.dark-theme .button-secondary {
  color: #F9F6FF;
  background-color: transparent;
  border: 1px solid white;
}

/*
 * General Stylistic Colors/Gradients
 */
/*
 * General Stylistic Colors/Gradients
 */
.dark-theme section, .dark-theme article, .dark-theme hr, .dark-theme div:not(.cdk-overlay-dark-backdrop):not(.cdk-global-overlay-wrapper):not(.cdk-overlay-backdrop):not(.cdk-overlay-pane), .dark-theme body, .dark-theme app-footer, .dark-theme input, .dark-theme textarea {
  background-color: #0B0B22;
}
.dark-theme router-outlet + * {
  background-color: #0B0B22;
}
.dark-theme h1, .dark-theme h2, .dark-theme h3, .dark-theme h4, .dark-theme h5, .dark-theme li, .dark-theme a, .dark-theme span, .dark-theme p, .dark-theme label, .dark-theme input, .dark-theme textarea, .dark-theme tr, .dark-theme th {
  color: #F9F6FF;
}
.dark-theme input, .dark-theme textarea {
  border: 1px solid #F9F6FF;
  border-radius: 10px;
  padding: 10px;
}

.light-theme section, .light-theme article, .light-theme hr, .light-theme div:not(.cdk-overlay-dark-backdrop):not(.cdk-global-overlay-wrapper):not(.cdk-overlay-backdrop):not(.cdk-overlay-pane), .light-theme body, .light-theme app-footer, .light-theme input, .light-theme textarea {
  background-color: #F5F5F6;
}
.light-theme router-outlet + * {
  background-color: #F5F5F6;
}
.light-theme h1, .light-theme h2, .light-theme h3, .light-theme h4, .light-theme h5, .light-theme li, .light-theme a, .light-theme span, .light-theme p, .light-theme label, .light-theme input, .light-theme textarea, .light-theme tr, .light-theme th {
  color: #11111E;
}
.light-theme input, .light-theme textarea {
  border: 1px solid #11111E;
  border-radius: 10px;
  padding: 10px;
}

.error {
  color: red;
}

navbar {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0px;
  z-index: 3;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

html, body, app {
  height: 100%;
}

.section-header {
  height: 10%;
  padding: 4rem 2rem 25px 2rem;
}
.section-header h2 {
  margin-bottom: 15px;
}
.section-header hr {
  border-top: 2px;
  border-top-style: solid;
  height: 0;
  margin: 0;
}
::ng-deep .light-theme .section-header hr {
  border-top-color: #11111E;
}
.light-theme .section-header hr {
  border-top-color: #11111E;
}
::ng-deep .dark-theme .section-header hr {
  border-top-color: #F9F6FF;
}
.dark-theme .section-header hr {
  border-top-color: #F9F6FF;
}

.card {
  aspect-ratio: 1/1;
  border: none;
}
.card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card h4 {
  margin: 10px 0 5px 0;
}

.card-round {
  aspect-ratio: 1/1;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.card-round img {
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
.card-round h4 {
  margin: 10px 0 5px 0;
}
.card-round .card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.card-set {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1rem;
  padding: 0 10px 0 10px;
}