// Dark Mode Colors
$dark-bg: #0B0B22;
$dark-text: #F9F6FF;
$dark-dropdown: #2E2E40;
$dark-dropdown-hover: #5B5F7A;
$dark-bg-accent: #17172B;

// Light Mode Colors
$light-bg: #F5F5F6;
$light-text: #11111E;
$light-dropdown: #EDEDFB;
$light-dropdown-hover: #DDDFEC;
$light-bg-accent: #F6F6FF;

/*
 * General Stylistic Colors/Gradients
 */
$primary: #4F55E0;


$dark-footer-fill: rgba(201, 210, 255, 0.20);
$light-footer-fill: rgba(201, 210, 255, 0.20);

$pop-up-fill: rgba(201, 210, 255, 0.20);
